import React, { useState } from 'react';

const App = () => {
  const [inputText, setInputText] = useState('');

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleButtonClick = () => {
    const modifiedText = inputText.split('').map(char => `||${char}||`).join('');
    
    // Copy to clipboard
    navigator.clipboard.writeText(modifiedText);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <div>
        <input
          type="text"
          value={inputText}
          onChange={handleInputChange}
          style={{ marginBottom: '10px', padding: '5px' }}
        />
        <br />
        <button onClick={handleButtonClick}>Submit</button>
      </div>
    </div>
  );
};

export default App;

